// import $ from 'jquery';
// import Swiper from 'swiper/bundle';
// import 'swiper/css';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
// gsap scrollTrigerr
gsap.registerPlugin(ScrollTrigger);

const isSmartPhone = () => {
  if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
    return true;
  } else {
    return false;
  }
};

const enteractives = gsap.utils.toArray('.js-enter-active');
if (enteractives) {
    enteractives.forEach((element, index) => {
        gsap.to(element, {
            scrollTrigger: {
                trigger: element,
                start: 'top center',
                onEnter: () => element.classList.add('is-active'),
            }
        });
    });
}